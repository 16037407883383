import cs from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { FiX } from 'react-icons/fi';

export enum ModalStyle {
  light,
  dark,
}

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  modalStyle?: ModalStyle;
  background?: string;
  opaque?: boolean;
  appElement?: HTMLElement;
  className?: string;
  backgroundClassName?: string;
  unstyled?: boolean;
  children?: ReactNode;
};

export const Modal = ({
  modalStyle = ModalStyle.dark,
  background,
  opaque = false,
  onClose,
  isOpen,
  unstyled,
  className,
  backgroundClassName,
  children,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const [firstRenderDone, setFirstRenderDone] = useState(false);

  useEffect(() => {
    if (opaque) return;
    if (!ref.current || !childrenRef.current) return;
    const close = (e: MouseEvent) => {
      if (childrenRef.current?.contains(e.target as Node)) {
        return;
      }
      onClose();
    };
    ref.current.addEventListener('mousedown', close);
    return () => {
      ref.current?.removeEventListener('mousedown', close);
    };
  }, [onClose, opaque, firstRenderDone]);

  useEffect(() => {
    const preventMove = (e: Event) => {
      e.preventDefault();
    };
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      ref.current?.addEventListener('touchmove', preventMove);
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
      ref.current?.removeEventListener('touchmove', preventMove);
    };
  }, [isOpen]);

  useEffect(() => {
    setFirstRenderDone(true);
  }, [typeof window === 'undefined']);

  if (!firstRenderDone) return null;

  return ReactDOM.createPortal(
    <div
      ref={ref}
      className={cs(
        { 'flex justify-center md:items-center md:p-4': !unstyled },
        backgroundClassName,
      )}
      style={{
        position: 'fixed',
        display: isOpen ? '' : 'none',
        overflow: 'auto',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: background
          ? background
          : modalStyle === ModalStyle.dark
            ? `rgba(0, 0, 0, ${opaque ? 1 : 0.5}`
            : `rgba(255, 255, 255, ${opaque ? 1 : 0.5})`,
        zIndex: 900,
      }}
    >
      {opaque ? (
        <div
          className="text-title color-text-primary absolute"
          style={{ top: 16, right: 16 }}
        >
          <button onClick={onClose}>
            <FiX />
          </button>
        </div>
      ) : null}
      <div
        ref={childrenRef}
        className={cs({ 'animation-scaledFadeIn': !unstyled }, className)}
      >
        {isOpen ? children : null}
      </div>
    </div>,
    document.body,
  );
};

export default Modal;
