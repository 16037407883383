import { useCallback, useEffect, useState } from 'react';

export const useLocalFlag = (
  key: string,
  version = 'v1',
): [boolean, () => void] => {
  const [hasFlag, _setHasFlag] = useState(false);
  const setHasFlag = useCallback(() => {
    localStorage.setItem(key, version);
    _setHasFlag(true);
  }, []);

  useEffect(() => {
    if (typeof localStorage === 'undefined') return;

    _setHasFlag(localStorage.getItem(key) === version);
  }, [typeof localStorage]);

  return [hasFlag, setHasFlag];
};
