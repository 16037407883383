import { useCountThirdPartyAppImportableShipmentsQuery } from '@/generated/graphql';

export const useCountImportableShipments = () => {
  const variables = {
    input: {
      excludeErrors: true,
      excludeWarnings: true,
    },
  };

  const { data } = useCountThirdPartyAppImportableShipmentsQuery({
    variables,
  });

  const totalImportableShipments =
    data?.countThirdPartyAppImportableShipments?.count;
  const importableShipmentsIsPaginated =
    data?.countThirdPartyAppImportableShipments?.hasMoreData;

  return {
    totalImportableShipments,
    importableShipmentsIsPaginated,
  };
};
