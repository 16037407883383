import { useClient } from '@packfleet/feature-flags';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useCurrentUserQuery } from '../generated/graphql';

let userIdentified = false;

export function useCurrentUser() {
  const { data } = useCurrentUserQuery();

  const user = data?.currentUser?.user;
  const userOrImpersonator = data?.currentUser?.impersonator || user;

  const intercom = useIntercom();
  const featureFlags = useClient();
  // Identify the user
  useEffect(() => {
    if (!userOrImpersonator || !userOrImpersonator.email || !user?.email) {
      return;
    }

    intercom.update({
      email: user?.email,
      name: user?.name,
      company: user?.organization
        ? { companyId: user.organization.id }
        : undefined,
      userId: user.id,
    });
    if (!userIdentified && featureFlags) {
      // For some services we only want to identify the user once per session
      void featureFlags
        .identify({
          key: userOrImpersonator.id,
          email: userOrImpersonator.email,
          anonymous: false,
          custom: {
            organizationId: userOrImpersonator.organization?.id ?? '',
          },
        })
        .then((flags) => {
          const sessionRecordingEnabled =
            flags['merchant-session-recording-enabled'];

          posthog.identify(userOrImpersonator.id, {
            email: userOrImpersonator.email,
          });

          const isDevelopment = process.env.NODE_ENV === 'development';
          if (!isDevelopment && sessionRecordingEnabled) {
            // Only use session recordings for external users
            posthog.startSessionRecording({});
          }
        });

      userIdentified = true;
    }
  }, [userOrImpersonator, intercom, featureFlags, userIdentified]);

  return user;
}
